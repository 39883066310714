import React, { useState, useMemo, useEffect } from 'react';
import { groupBy, capitalize, keyBy } from 'lodash';
import classnames from 'classnames';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment';

import { useStateValue } from 'state/State';
import useRequest from 'utils/useRequest';
import Error from 'utils/Error';
import Loading from 'utils/Loading';
import { balanceToClass } from 'utils/functions';
import Article from 'components/Article';

import CategoryModal from './Transactions/CategoryModal';
import UpdateCoverflexModal from './Transactions/UpdateCoverflexModal';
import UpdateNordigenModal from './Transactions/UpdateNordigenModal.js';
import ImportTransactionsForm from './Transactions/ImportTransactionsForm';

import classes from './Transactions.module.scss';

const financial = (x) => Number.parseFloat(x.toFixed(2));

const Transactions = () => {
  const [{ user }] = useStateValue();
  const [updateCoverflexModal, setUpdateCoverflexModal] = useState();
  const [updateNordigenModal, setUpdateNordigenModal] = useState();
  const [categoryModal, setCategoryModal] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [hideTransfers, setHideTransfers] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();

  const {
    data: accounts,
    loading: loadingAccounts,
    error: errorAccounts,
  } = useRequest({
    url: 'bank-accounts',
  });

  const {
    data: transactions,
    loading: loadingTransactions,
    error: errorTransactions,
    request: reloadTransactions,
  } = useRequest({
    url: 'bank-transactions',
    method: 'GET',
    callback: !user,
  });

  const {
    data: categories,
    loading: loadingCategories,
    error: errorCategories,
  } = useRequest({
    url: 'categories',
    method: 'GET',
    callback: !user,
  });

  const categoriesById = useMemo(() => keyBy(categories, 'id'), [categories]);

  const flowTransactions = useMemo(() => {
    let filteredTransactions = hideTransfers
      ? transactions?.filter(
          (item) => !categoriesById?.[item.category?.id]?.hidden
        )
      : transactions;
    if (selectedAccount) {
      filteredTransactions = filteredTransactions?.filter(
        (item) => item.bank_account?.id === parseInt(selectedAccount)
      );
    }
    return filteredTransactions;
  }, [transactions, categoriesById, hideTransfers, selectedAccount]);

  const groupedTransactions = useMemo(
    () => groupBy(flowTransactions, 'date'),
    [flowTransactions]
  );

  const fullGroupedTransactions = useMemo(
    () => groupBy(transactions, 'date'),
    [transactions]
  );

  const transactionsDates = useMemo(
    () =>
      groupedTransactions &&
      Object.keys(groupedTransactions)
        .filter((date) => {
          const currentDate = new Date(date);
          if (
            currentDate >= new Date(startDate) &&
            currentDate <= new Date(endDate)
          ) {
            return true;
          }
          return false;
        })
        .sort((a, b) => new Date(b) - new Date(a)),
    [groupedTransactions, startDate, endDate]
  );

  const periodsAvailable = useMemo(() => {
    const periods = Object.values(
      Object.keys(fullGroupedTransactions).reduce((acc, item) => {
        const year = item.split('-')[0];
        const month = item.split('-')[1];
        acc[year] = {
          value: `${moment(item).startOf('year').format('YYYY-MM-DD')}/${moment(
            item
          )
            .endOf('year')
            .format('YYYY-MM-DD')}`,
          label: year,
          sort: `${year}-00`,
        };
        acc[`${year}-${month}`] = {
          value: `${moment(item)
            .startOf('month')
            .format('YYYY-MM-DD')}/${moment(item)
            .endOf('month')
            .format('YYYY-MM-DD')}`,
          label: `${capitalize(
            new Date(
              `1970-${month.toString().padStart(2, '0')}-15`
            ).toLocaleString('pt-PT', {
              month: 'long',
            })
          )} de ${year}`,
          sort: `${year}-${month.toString().padStart(2, '0')}`,
        };
        return acc;
      }, {})
    );
    const currentYearLabel = moment().startOf('year').format('YYYY');
    const currentYear = {
      label: currentYearLabel,
      value: `${moment().startOf('year').format('YYYY-MM-DD')}/${moment()
        .endOf('year')
        .format('YYYY-MM-DD')}`,
      sort: `${currentYearLabel}-00`,
    };
    if (!periods.filter((item) => item.label === currentYear.label).length) {
      periods.push(currentYear);
    }
    return periods.sort((a, b) => a.sort.localeCompare(b.sort));
  }, [fullGroupedTransactions]);

  useEffect(() => {
    const dates =
      periodsAvailable[periodsAvailable.length - 1]?.value.split('/');
    setStartDate(dates?.[0]);
    setEndDate(dates?.[1]);
  }, [periodsAvailable]);

  const accByCategory = useMemo(() => {
    const transactionByCategory = groupBy(flowTransactions, 'category.id');
    return Object.keys(transactionByCategory).reduce((acc, item) => {
      acc[item] = transactionByCategory[item].reduce(
        (innerAcc, transaction) => {
          const currentDate = new Date(transaction.date);
          if (
            currentDate >= new Date(startDate) &&
            currentDate <= new Date(endDate)
          ) {
            return financial(innerAcc + transaction.value);
          }
          return innerAcc;
        },
        0
      );
      return acc;
    }, {});
  }, [flowTransactions, startDate, endDate]);

  const expenses = useMemo(
    () => categories?.filter((item) => accByCategory[item.id] < 0),
    [categories, accByCategory]
  );

  const gains = useMemo(
    () => categories?.filter((item) => accByCategory[item.id] > 0),
    [categories, accByCategory]
  );

  const totalGains = useMemo(
    () =>
      gains?.reduce((acc, item) => financial(acc + accByCategory[item.id]), 0),
    [gains, accByCategory]
  );

  const totalExpenses = useMemo(
    () =>
      expenses?.reduce(
        (acc, item) => financial(acc - accByCategory[item.id]),
        0
      ),
    [expenses, accByCategory]
  );

  const cashflow = financial(totalGains - totalExpenses);

  const accByAccount = useMemo(() => {
    const transactionByAccount = groupBy(transactions, 'bank_account.id');
    return Object.keys(transactionByAccount).reduce((acc, item) => {
      acc[item] = transactionByAccount[item].reduce(
        (innerAcc, transaction) => financial(innerAcc + transaction.value),
        0
      );
      return acc;
    }, {});
  }, [transactions]);

  const accountBalances = useMemo(
    () =>
      accounts
        ?.map((account) => ({
          ...account,
          balance: financial(
            account.initial_balance + (accByAccount[account.id] || 0)
          ),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [accounts, accByAccount]
  );

  if (!user) {
    return <Error error={403} />;
  }

  if (errorTransactions || errorCategories || errorAccounts) {
    return <Error />;
  }

  if (loadingTransactions || loadingCategories || loadingAccounts) {
    return <Loading />;
  }

  return (
    <Article article={{ title: 'Movimentos' }}>
      <div>
        <h2>Balanços das contas</h2>
        <ul className={classes.accounts}>
          {accountBalances.map((account) => {
            if (account.balance) {
              return (
                <li key={account.id}>
                  <strong>{account.title}:</strong>{' '}
                  {account.balance.toLocaleString('pt-PT')} €
                </li>
              );
            }
            return null;
          })}
        </ul>
        <button
          className={classes.button}
          onClick={() => {
            setUpdateNordigenModal(true);
          }}
        >
          <i className="fas fa-rotate"></i>{' '}
          {accountBalances
            .map((account) =>
              account.source === 'nordigen' ? account.title : null
            )
            .filter(Boolean)
            .join(', ')
            .replace(/, ([^,]*)$/, ' e $1')}
        </button>
        <button
          className={classes.button}
          onClick={() => {
            setUpdateCoverflexModal(true);
          }}
        >
          <i className="fas fa-rotate"></i> Coverflex
        </button>
        <hr />
        {periodsAvailable.length > 0 && (
          <>
            <label>
              Período:{' '}
              <select
                onChange={(event) => {
                  const dates = event.target.value.split('/');
                  setStartDate(dates[0]);
                  setEndDate(dates[1]);
                }}
                defaultValue={
                  periodsAvailable[periodsAvailable.length - 1].value
                }
              >
                {periodsAvailable.map((period) => (
                  <option key={period.value} value={period.value}>
                    {period.label}
                  </option>
                ))}
              </select>
            </label>
            <br />
            <br />
          </>
        )}
        <label>
          Conta:{' '}
          <select
            onChange={(event) => {
              setSelectedAccount(event.target.value);
            }}
          >
            <option value="">Todas</option>
            {accounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.title}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className={classes.charts}>
        <hr />
        <h2>Distribuição da origem de rendimentos e despesas</h2>
        <label>
          Esconder transferências{' '}
          <input
            type="checkbox"
            checked={hideTransfers}
            value={hideTransfers}
            onChange={() => {
              setHideTransfers((prev) => !prev);
            }}
          />
        </label>
        <br />
        <br />
        <strong>
          <u>Fluxo de caixa:</u>
        </strong>{' '}
        <span className={classes[balanceToClass(cashflow)]}>
          {cashflow.toLocaleString('pt-PT')} €
        </span>
        {totalGains > 0 && (
          <>
            <h3>Rendimentos</h3>
            <strong>
              <u>Total:</u>
            </strong>{' '}
            <span className={classes.up}>
              {totalGains.toLocaleString('pt-PT')} €
            </span>
            <div className={classes.pieContainer}>
              <Doughnut
                data={{
                  labels: gains.map(
                    (category) =>
                      `${category.emoji} ${category.title} (${accByCategory[
                        category.id
                      ].toLocaleString('pt-PT')} €)`
                  ),
                  datasets: [
                    {
                      data: gains.map((category) => accByCategory[category.id]),
                      backgroundColor: gains.map((category) =>
                        category.color ? `#${category.color}` : '#FFFFFF'
                      ),
                      hoverOffset: 20,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: 20,
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => context.label,
                      },
                    },
                    legend: {
                      position: 'right',
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pieContainerMobile}>
              <Doughnut
                data={{
                  labels: gains.map(
                    (category) =>
                      `${category.emoji} ${category.title} (${accByCategory[
                        category.id
                      ].toLocaleString('pt-PT')} €)`
                  ),
                  datasets: [
                    {
                      data: gains.map((category) => accByCategory[category.id]),
                      backgroundColor: gains.map((category) =>
                        category.color ? `#${category.color}` : '#FFFFFF'
                      ),
                      hoverOffset: 20,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: 20,
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => context.label,
                      },
                    },
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </>
        )}
        {totalExpenses > 0 && (
          <>
            <h3>Despesas</h3>
            <strong>
              <u>Total:</u>
            </strong>{' '}
            <span className={classes.down}>
              {totalExpenses.toLocaleString('pt-PT')} €
            </span>
            <div className={classes.pieContainer}>
              <Doughnut
                data={{
                  labels: expenses.map(
                    (category) =>
                      `${category.emoji} ${category.title} (${Math.abs(
                        accByCategory[category.id]
                      ).toLocaleString('pt-PT')} €)`
                  ),
                  datasets: [
                    {
                      data: expenses.map(
                        (category) => accByCategory[category.id]
                      ),
                      backgroundColor: expenses.map((category) =>
                        category.color ? `#${category.color}` : '#FFFFFF'
                      ),
                      hoverOffset: 20,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: 20,
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => context.label,
                      },
                    },
                    legend: {
                      position: 'right',
                    },
                  },
                }}
              />
            </div>
            <div className={classes.pieContainerMobile}>
              <Doughnut
                data={{
                  labels: expenses.map(
                    (category) =>
                      `${category.emoji} ${category.title} (${Math.abs(
                        accByCategory[category.id]
                      ).toLocaleString('pt-PT')} €)`
                  ),
                  datasets: [
                    {
                      data: expenses.map(
                        (category) => accByCategory[category.id]
                      ),
                      backgroundColor: expenses.map((category) =>
                        category.color ? `#${category.color}` : '#FFFFFF'
                      ),
                      hoverOffset: 20,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: 20,
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => context.label,
                      },
                    },
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
      <hr />
      <h2>Movimentos</h2>
      <ul className={classes.transactionDates}>
        {transactionsDates.map((transactionDate) => (
          <li key={transactionDate}>
            <span className={classes.transactionDate}>{transactionDate}</span>
            <ul className={classes.transactions}>
              {groupedTransactions[transactionDate].map((transaction) => (
                <li key={transaction.id} className={classes.transaction}>
                  <div
                    className={classes.category}
                    title={transaction.category?.title}
                    onClick={() => setCategoryModal(transaction)}
                  >
                    {transaction.category?.emoji || '❔'}
                  </div>
                  <div className={classes.account}>
                    {transaction.bank_account?.title || '-'}
                  </div>
                  <div
                    className={classes.description}
                    title={transaction.description}
                  >
                    {transaction.description}
                  </div>
                  <div
                    className={classnames(classes.value, {
                      [classes.positive]: transaction.value > 0,
                      [classes.negative]: transaction.value < 0,
                    })}
                  >
                    {transaction.value.toLocaleString('pt-PT')} €
                  </div>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      {updateNordigenModal && (
        <UpdateNordigenModal
          accounts={keyBy(accounts, 'id')}
          onClose={({ saved }) => {
            setUpdateNordigenModal();
            if (saved) {
              reloadTransactions();
            }
          }}
        />
      )}
      {updateCoverflexModal && (
        <UpdateCoverflexModal
          onClose={({ saved }) => {
            setUpdateCoverflexModal();
            if (saved) {
              reloadTransactions();
            }
          }}
        />
      )}
      {categoryModal && (
        <CategoryModal
          categories={categories}
          transaction={categoryModal}
          onClose={({ saved }) => {
            setCategoryModal();
            if (saved) {
              reloadTransactions();
            }
          }}
        />
      )}
      <ImportTransactionsForm
        accounts={accounts}
        onSuccess={reloadTransactions}
      />
    </Article>
  );
};

export default Transactions;
