import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/pt';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';

import classes from './Data.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
const currentYear = yesterday.getFullYear();
const dayInYear = Math.floor(
  (yesterday - new Date(currentYear, 0, 0)) / (1000 * 60 * 60 * 24)
);

const options = [
  {
    label: '1m',
    timespan: 30,
  },
  {
    label: '6m',
    timespan: 30 * 6,
  },
  {
    label: '1a',
    timespan: 365,
  },
  {
    label: '5a',
    timespan: 365 * 5,
  },
  {
    label: '10a',
    timespan: 365 * 10,
  },
  {
    label: currentYear,
    timespan: dayInYear,
  },
  {
    label: 'Máximo',
    timespan: 0,
  },
];

const Euribor = () => {
  const [firstLoad, setFirstLoad] = useState(false);
  const [timespan, setTimespan] = useState(365);
  const [hideYTD, setHideYTD] = useState(false);

  const [visibleDatasets, setVisibleDatasets] = useState({
    'Euribor 3m': false,
    'Euribor 6m': true,
    'Euribor 12m': true,
  });

  const {
    request: request3m,
    data: data3m,
    loading: loading3m,
    error: error3m,
  } = useRequest({
    url: `/proxy?url=https://quotes.ifra.pt/euribor_3m.json`,
    method: 'GET',
    callback: true,
  });

  const {
    request: request6m,
    data: data6m,
    loading: loading6m,
    error: error6m,
  } = useRequest({
    url: `/proxy?url=https://quotes.ifra.pt/euribor_6m.json`,
    method: 'GET',
    callback: true,
  });

  const {
    request: request12m,
    data: data12m,
    loading: loading12m,
    error: error12m,
  } = useRequest({
    url: `/proxy?url=https://quotes.ifra.pt/euribor_12m.json`,
    method: 'GET',
    callback: true,
  });

  useEffect(() => {
    request3m();
    request6m();
    request12m();
  }, [request3m, request6m, request12m]);

  const chartData = useMemo(() => {
    if (data3m && data6m && data12m) {
      let dates = [];
      let labels = [];
      let data3 = [];
      let data6 = [];
      let data12 = [];

      const begin = timespan
        ? moment().startOf('day').subtract(timespan, 'day').valueOf()
        : undefined;
      const days = Object.keys(data12m);
      const availableDays = days.reduce((acc, item, index) => {
        if (
          moment(item, 'YYYY-MM-DD').startOf('day').valueOf() >= begin ||
          !begin
        ) {
          if (!acc.length && days[index - 1]) {
            acc.push(days[index - 1]);
          }
          acc.push(item);
        }
        return acc;
      }, []);
      const points = availableDays.length > 200 ? 200 : availableDays.length;
      const dayStep = parseInt(availableDays.length / points);
      availableDays.forEach((element, index) => {
        if (index % dayStep === 0) {
          dates.push(element);
          data3.push(data3m[element]);
          data6.push(data6m[element]);
          data12.push(data12m[element]);
          labels.push(element);
        }
      });
      return {
        labels,
        dates,
        data3m: data3,
        data6m: data6,
        data12m: data12,
      };
    }
    return undefined;
  }, [data3m, data6m, data12m, timespan]);

  useEffect(() => {
    if (
      !firstLoad &&
      !loading3m &&
      !loading6m &&
      !loading12m &&
      error3m &&
      error6m &&
      error12m
    ) {
      setTimespan(365);
      setHideYTD(true);
      setFirstLoad(true);
    }
    if (chartData && !firstLoad) {
      if (chartData.dates.length < 30) {
        setTimespan(0);
      }
      if (chartData.dates.length < 2) {
        setHideYTD(true);
      }
      setFirstLoad(true);
    }
  }, [
    firstLoad,
    chartData,
    loading3m,
    loading6m,
    loading12m,
    error3m,
    error6m,
    error12m,
    request3m,
    request6m,
    request12m,
  ]);

  if (error3m || error6m || error12m) {
    return (
      <div
        style={{
          height: '400px',
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
        }}
      >
        <Error />
      </div>
    );
  }

  if (
    !data3m ||
    !data6m ||
    !data12m ||
    !chartData ||
    loading3m ||
    loading6m ||
    loading12m
  ) {
    return (
      <div
        style={{
          height: '400px',
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div style={{ height: '400px' }}>
      <div className={classes.buttons}>
        <span className={classes.options}>
          {options.map((option) => {
            if (hideYTD && option.label === currentYear) {
              return null;
            }
            return (
              <button
                key={option.timespan}
                className={classnames({
                  [classes.selected]: timespan === option.timespan,
                })}
                onClick={() => {
                  setTimespan(option.timespan);
                }}
              >
                {option.label}
              </button>
            );
          })}
        </span>
      </div>
      <div style={{ height: '300px' }}>
        <Line
          data={{
            labels: chartData.labels,
            datasets: [
              {
                label: 'Euribor 3m',
                data: chartData.data3m,
                borderColor: '#136F63',
                backgroundColor: '#136F63',
                hidden: !visibleDatasets['Euribor 3m'],
              },
              {
                label: 'Euribor 6m',
                data: chartData.data6m,
                borderColor: '#C1EEFF',
                backgroundColor: '#C1EEFF',
                hidden: !visibleDatasets['Euribor 6m'],
              },
              {
                label: 'Euribor 12m',
                data: chartData.data12m,
                borderColor: '#FFA400',
                backgroundColor: '#FFA400',
                hidden: !visibleDatasets['Euribor 12m'],
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            borderWidth: 2,
            tension: 0,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                },
                onClick: function (_, legendItem) {
                  var index = legendItem.datasetIndex;
                  var ci = this.chart;
                  var meta = ci.getDatasetMeta(index);
                  meta.hidden =
                    meta.hidden === null
                      ? !ci.data.datasets[index].hidden
                      : null;
                  setVisibleDatasets((prev) => ({
                    ...prev,
                    [legendItem.text]: !Boolean(meta.hidden),
                  }));
                  ci.update();
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => chartData.dates[context[0].dataIndex],
                  label: (context) => {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label +=
                        new Intl.NumberFormat('pt-PT').format(
                          context.parsed.y
                        ) + '%';
                    }
                    return label;
                  },
                },
              },
            },
            pointRadius: 0,
            scales: {
              xAxis: {
                type: 'time',
                time: {
                  unit: chartData.dates.length < 30 ? 'day' : undefined,
                },
                grid: {
                  color: (context) =>
                    context.tick.label ? 'rgba(0,0,0,0.2)' : 'transparent',
                },
              },
              yAxis: {
                title: {
                  display: true,
                  text: 'Taxa de juro (%)',
                },
                grid: {
                  color: 'rgba(0,0,0,0.2)',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Euribor;
